.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.imageSize {
  width: 58px;
  height: 68px;
  cursor: pointer;
}

.viewPhoto {
  max-width: 700px;
  max-height: 500px;
  margin: auto;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: auto;
  color: #95a2aa;
  line-height: 1.5;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}

.kerosene {
  background-color: #019f3e;
}

.gas105 {
  background-color: #753bbd;
}

.lpg {
  background-color: #ffaa01;
}
