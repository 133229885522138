.container {
  width: 1200px;
  .fieldLabel {
    .radioContainer {
      gap: 34%;
    }
    .radioContainerStatus {
      gap: 30.3%;
    }
  }
  .fieldLabel > p {
    width: 22%;
  }
  .fieldLabel > div {
    width: 22%;
  }
  .fieldText {
    color: var(--color-blue-gray);
    font-weight: 500;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .addProduct {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .productRowHeader {
      display: flex;
      justify-content: space-between;
      > div > div {
        width: 100%;
      }
    }
    .productRowBody {
      display: flex;
      justify-content: space-between;
      > div > div {
        width: 100%;
      }
    }
  }

  .fuelCategory {
    width: 100%;
  }
  .benchMarkProduct {
    width: 100%;
  }
  .dateRange {
    width: 270px;
  }
  .gasAmount {
    width: 120px;
  }
  .continue {
    margin-top: 30px;
    height: 39px;
    padding: 12px 40px;
    font-weight: 500;
  }
  .attributesInput {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 10px;
    .cohortDropdown {
      width: 320px;
    }
  }
  .removeCohort {
    align-self: center;
    margin-left: 10px;
    padding-top: 5px;
    cursor: pointer;
  }
  .excludeAttribute {
    display: flex;
    width: 240px;
    align-items: center;
    padding-left: 20px;
    color: var(--color-blue-gray);
  }
  .numberStationSelected {
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: var(--color-purple);
    font-weight: 500;
  }
  .shoulderedBy {
    width: 60%;
  }
  .accountTypes {
    width: 400px;
  }
  .linkDetails {
    font-weight: 500;
    color: var(--color-purple);
    text-decoration: underline;
    cursor: pointer;
  }
  .usersWithoutThisAttribute,
  input {
    font-weight: 500;
    color: var(--color-blue-gray);
  }
}

.tableView {
  width: 100%;
  padding-top: 35px;
  thead > tr > th {
    text-align: left;
  }

  .addOrEditTd {
    width: 30%;
  }
  .viewTd {
    width: 17.8%;
  }
  .productLoop {
    width: 15% !important;
  }
  tbody > tr > td > div > div {
    width: 90% !important;
    .fuelCategory {
      width: 100%;
    }
    .benchMarkProduct {
      width: 100%;
    }
  }

  tbody > tr {
    line-height: 3px;
  }
}
