.popoverContainer {
  max-height: 600px;
  margin-left: -80px;
}
.top {
  margin-left: 0px;
  top: -25px !important;
}
.right {
  margin-left: 70px;
  top: -25px !important;
}
.bottom {
  margin-left: 0px;
  top: 50px !important;
}
.container {
  width: fit-content;
}
.content {
  font-family: "Roboto";
  font-size: 14px;
  padding: 12px 20px;
  &:hover {
    font-weight: 500;
    color: var(--color-purple);
    background: var(--color-baby-blue);
  }
}

.icon {
  color: var(--color-night);
}
