.container {
  background-color: var(--color-metalic-grey);

  height: 50px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

.text {
  color: var(--color-night);
  margin: auto;
}
