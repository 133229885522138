.infoContainer {
  position: absolute;
  width: 450px;
  height: 537px;
  margin-top: 10px;
  margin-left: 10px;
  background-color: var(--color-white);
  z-index: 1;
  border-radius: 8px;
  top: 20px !important;
  .circleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin-top: 250px;
  }
}

.openTab,
.closeTab {
  height: 60px;
  width: 24px;
  margin-top: 70px;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  background-color: var(--color-purple);
  z-index: 1;
  color: var(--color-white);
  font-weight: 500;
  padding: 22px 0 0 7px;
}

.openTab {
  position: relative;
  float: left;
}

.closeTab {
  float: right;
  margin-right: -24px;
}

.notAllowAccessLocation {
  position: absolute;
  border-radius: 5px;
  right: 70px;
  bottom: 24px;
  width: 280px;
  height: 80px;
  padding: 20px;
  background-color: var(--color-white);
  color: var(--color-cement);
}

.markerLabel {
  margin-top: 50px;
  text-align: right !important;
  background: #753bbd23;
  color: #2e4491 !important;
  border-radius: 3px;
  padding: 2px 4px;
  font-weight: 500;
}
