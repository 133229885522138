.container {
  width: 1200px;
  .fieldLabel {
    .radioContainer {
      gap: 34%;
    }
    .radioContainerStatus {
      gap: 30.3%;
    }
  }
  .fieldLabel > p {
    width: 22%;
  }
  .fieldLabel > div {
    width: 38%;
  }
  .fieldText {
    color: var(--color-blue-gray);
    font-weight: 500;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .addProduct {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .productRowHeader {
      display: flex;
      justify-content: space-between;
      > div > div {
        width: 100%;
      }
    }
    .productRowBody {
      display: flex;
      justify-content: space-between;
      > div > div {
        width: 100%;
      }
    }
  }

  .fuelCategory {
    width: 100%;
  }
  .benchMarkProduct {
    width: 100%;
  }
  .dateRange {
    width: 270px;
  }
  .gasAmount {
    width: 120px;
  }
  .continue {
    margin-top: 30px;
    height: 39px;
    padding: 12px 40px;
    font-weight: 500;
  }
  .attributesInput {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 10px;
    .cohortDropdown {
      width: 320px;
    }
  }
  .removeCohort {
    align-self: center;
    margin-left: 10px;
    padding-top: 5px;
    cursor: pointer;
  }
  .excludeAttribute {
    display: flex;
    width: 240px;
    align-items: center;
    padding-left: 20px;
    color: var(--color-blue-gray);
  }
  .numberStationSelected {
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: var(--color-purple);
    font-weight: 500;
  }
  .shoulderedBy {
    width: 60%;
  }
  .accountTypes {
    width: 400px;
  }
  .linkDetails {
    font-weight: 500;
    color: var(--color-purple);
    text-decoration: underline;
    cursor: pointer;
  }
  .usersWithoutThisAttribute,
  input {
    font-weight: 500;
    color: var(--color-blue-gray);
  }
}

table {
  width: 100%;
  thead > tr > th {
    text-align: left;
  }

  .addOrEditTd {
    width: 30%;
  }
  .viewTd {
    width: 15%;
  }
  .productLoop {
    width: 15% !important;
  }
  tbody > tr > td > div > div {
    width: 100% !important;
    .fuelCategory {
      width: 100%;
    }
    .benchMarkProduct {
      width: 100%;
    }
  }
}

.tableStationDetails {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: left;
    margin-bottom: 18px;
    label:first-child {
      font-size: 14px;
      font-weight: normal;
      color: var(--color-cement);
      width: 14%;
    }
    label:last-child {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-night);
      width: 87%;
    }
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}

.kerosene {
  background-color: #019f3e;
}

.gas105 {
  background-color: #753bbd;
}

.lpg {
  background-color: #ffaa01;
}

.productList {
  display: flex;
  gap: 50px;
}

.parentMap {
  position: relative;
}

.search {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.inputFieldSearch {
  padding: 5px 5px;
  border-radius: 2px;
  background: white;
  color: #fff;
  width: 50%;
  margin-left: 20px;
}
